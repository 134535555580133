import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import * as styled from './styles';
import MhsStatsPure from './Stats';
import Hit from '@components/SearchHit';

const SearchInstantPage = ({ searchClient, query }) => (
  <>
      <InstantSearch searchClient={searchClient} indexName="main">
        <styled.MhsSearchBox
          defaultRefinement={query}
          searchAsYouType={false}
          translations={{
            placeholder: 'Enter search term here',
          }}
        />
        <Configure
          filters={'type:page OR type:story OR type:gallery OR type:event OR type:featured'}
          hitsPerPage={12}
          attributesToSnippet={['content']}
          analytics={false}
          enablePersonalization={false}
          distinct
        />
        <styled.MhsStats>
          <MhsStatsPure />
        </styled.MhsStats>
        <styled.MhsInfiniteHits hitComponent={Hit} />
      </InstantSearch>
  </>
);

export default SearchInstantPage;
