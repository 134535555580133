import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import qs from 'query-string';
import Footer from '@components/Footer';
import Navigation, { NavSpacer } from '@components/Navigation';
import SEO from '@components/SEO';
import SearchInstantPage from '@components/SearchInstantPage';
import Container from '@components/Container';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};


const Search2Page = ({ banner, navItems, location }) => {
  const title = 'Search';
  const query = qs.parse(location.search)?.query;

  return (
    <>
      <Navigation banner={banner} navItems={navItems} />
      <main>
        <SEO title={title} />
        <NavSpacer />
        <Container centered>
          <SearchInstantPage {...{searchClient, query}} />
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default Search2Page;
