import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

import { useIntl } from '@utils/localize';

const MhsStatsPure = ({ searchResults }) => {
  const intl = useIntl();

  if (searchResults) {
    return (
      <div>
        <span>&#8220;{searchResults.query}&#8221;</span> {searchResults.nbHits}{' '}
        {intl.formatMessage({ id: 'results' })}:
      </div>
    );
  }

  return null;
};

export default connectStateResults(MhsStatsPure);
